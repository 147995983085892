import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import ImageCallout from '@components/ImageCallout';
import PreFooter from '@components/PreFooter';
import {stringToUrl, stripTagsFromWagtail, propertyHasUnits} from '@util';

import type {PropertyType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...PropertyType[],
    },
  },
};

function getIndexForProperties(propertie){
  if (propertie){
    switch (propertie.toLowerCase()) {
      case 'the epic':
        return 0;
      case '345e94':
        return 1;
      case 'the victory':
        return 2;
      default:
        return false;
    }
  }
}


export default ({data}: Props) => {
  const {page, properties} = data.wagtail;
  const sectionRef = React.createRef();
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroImage,
    heroCopy,
    heroCtaText,
  } = page;

  var orderdProperties = [];
  
  // List of property names to exclude
  const excludedProperties = ['The Victory', '345E94', '298 Mulberry'];

  // Filtering out the excluded properties
  for (let index = 0; index < properties.length; index++) {
    const propertyName = properties[index].name;
    if (!excludedProperties.includes(propertyName)) {
      const auxProperties = getIndexForProperties(propertyName);
      if(auxProperties !== false)
        orderdProperties[auxProperties] = properties[index];
      else
        orderdProperties.push(properties[index]);
    }
  }

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
      </Helmet>
      <Layout>
        <Hero
          title={heroHeadline}
          background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${
            heroImage.url
          })`}
          body={stripTagsFromWagtail(heroCopy)}
          scrollToLink
          copy={heroCtaText}
          sectionRef={sectionRef}
        />
        <div id="second-section" ref={sectionRef}></div>
        {orderdProperties &&
          orderdProperties.length > 0 &&
          orderdProperties.filter(propertyHasUnits).map((property, index) => (
            <Section key={property.name}>
              <ImageCallout
                eyebrow={
                  property.address.neighborhood.name
                    ? property.address.neighborhood.name
                    : null
                }
                imageSrc={property.heroImage && property.heroImage.image.url}
                imgMaxHeight="600px"
                imgMaxWidth="50%"
                headline={property.name}
                copy={property.description}
                imageLeft={index % 2 === 0 ? false : true}
                textAlign="left"
                button={{
                  buttonLink: `/properties/${stringToUrl(property.name)}/`,
                  buttonText: `Check out ${property.name}`,
                }}
              />
            </Section>
          ))}
        <PreFooter />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment PropertiesLandingFragment on Wagtail_PropertyLandingPage {
    seoTitle
    searchDescription
    heroCopy
    heroHeadline
    heroImage {
      url
    }
    heroCtaText
  }

  query {
    wagtail {
      page(slug: "properties") {
        ...PropertiesLandingFragment
      }
      properties {
        name
        soldout
        description
        heroImage {
          image {
            url
          }
        }
        address {
          neighborhood {
            slug
            name
          }
        }
        floorplans {
          units {
            available
          }
        }
      }
    }
  }
`;